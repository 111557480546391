/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/application.scss'

import 'bootstrap/dist/js/bootstrap'
import ujs from '@rails/ujs'
import 'select2'
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/src/tagify.scss'
import MicroModal from 'micromodal'

ujs.start()

$(() => {
  $('select').select2({theme: 'bootstrap4', width: '100%'})

  $('#final_product_universal_product_id').change((event) => {
    const newVal = $(event.target).val()
    if (newVal) {
      $('#final_product_category_id').val(window.productCategories[newVal]).change()
    }
  })

  let blankRow = null
  if ($('#blank-row').length > 0) {
    blankRow = $('#blank-row').clone()
    blankRow.find('.select2').remove()
    $('#blank-row').remove()

    $('#insert-blank-row').click(() => {
      $(blankRow.prop('outerHTML').replace(/INDEX/g, Date.now())).insertBefore($('#blank-row-target'))
      $('select').select2({theme: 'bootstrap4', width: '100%'})
    })

    $(document).on('click', '.remove-row', (event) => {
      $(event.target).closest('tr').remove()
    })
  }

  $('#brand-add').click((e) => {
    e.preventDefault()
    $.post('/config/brands', {brand: {name: $('#brand-add').data('value')}}, (data) => {
      const newOption = new Option(data.object.name, data.object.id, true, true)
      $('#final_product_brand_ids').append(newOption).trigger('change')
      $('#brand-add').remove()
    }, 'json')
  })

  $('#modal-brand-add').on("ajax:success", (event) => {
      if(event.detail[0].status) {
        const newOption = new Option(event.detail[0].object.name, event.detail[0].object.id, true, true)
        $('#final_product_brand_ids').append(newOption).trigger('change')
        MicroModal.close('modal-brand-add')
      }
    })

  $('.copy-value').click((event) => {
    $(`*[name="final_product[${event.target.dataset.target}]"]`).val(event.target.dataset.value);
  })

  $('.copy-value-all').click(() => {
    $('.copy-value').click();
  });

  new Tagify(document.querySelector('#final_product_allergens'))
  new Tagify(document.querySelector('#final_product_ingredients'))
  MicroModal.init();
})
